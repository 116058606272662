import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Tag ({ pageContext, data }) {
  console.log(data)
  console.log(pageContext)
  return (
    <Layout width={"720px"}>    
        <div className="container">
          <div className="row justify-content-center">
            <div className="col index--inner tag-index--inner">
                <h1 className="tag-index--tag-name">
                  <span style={{ /* inspiration: https://justworks.com/ */
                      backgroundImage: 'linear-gradient(to right, #A2CDD0 50%, #A2CDD0 50%)',
                      backgroundPosition: '0 0.99em',
                      backgroundRepeat: 'repeat-x',
                      backgroundSize: '1px 8px'
                    }}>
                      {pageContext.tag}
                  </span>
                </h1>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <article key={node.id}>
                    <Link to={node.fields.slug}>
                    <h3 dangerouslySetInnerHTML={{__html: node.frontmatter.title}} />
                    </Link>
                    <p dangerouslySetInnerHTML={{__html: node.frontmatter.customExcerpt || node.excerpt}} />
                  </article>
                ))}   
            </div>
          </div>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___publishDate], order: DESC }
      filter: { 
        frontmatter: { 
          tags: { in: [$tag] }, 
          isDraft: {ne: true}
        }
      }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            customExcerpt
            title
          }
          fields {
            slug
          }
        excerpt(pruneLength: 280)
        }
      }
    }
  }
`
